<template>
  <div>
    <div class="toolbar py-5 py-lg-15">
      <div class="container-xxl d-flex flex-stack flex-wrap align-items-start">
        <div class="page-title d-flex flex-column me-3">
          <h1 class="text-white">Projects</h1>

          <px-new-breadcrumbs :breadcrumbs="breadcrumbs" />
        </div>

        <div class="d-flex align-items-center align-self-start">
          <div v-if="hasMasterRole" class="me-4">
            <px-select
              v-if="innerUsers.length"
              classes="form-select form-select-semi-transparent fw-normal min-w-200px ps-4"
              v-model="managerId"
              placeholder="Select manager"
              :items="innerUsers"
              track-by="id"
              :text-by="['attributes.first_name', ' ', 'attributes.last_name']"
              :minimum-results-for-search="Infinity"
              select2-id="select2_manager_id"
              @input="changeManger"
            />
          </div>
          <router-link
            :to="{ name: 'projects-archive' }"
            class="btn btn-sm btn-custom btn-active-white btn-flex btn-color-white btn-active-color-primary"
          >
            <i class="bi bi-archive me-1" />
            Archive
          </router-link>
        </div>
      </div>
    </div>

    <!--begin::Container-->
    <div class="container-xxl pb-10">
      <!--begin::Content-->
      <div class="content flex-row-fluid">
        <div class="card mb-5 mb-xxl-8">
          <div class="card-body py-9">
            <div class="row align-items-stretch g-6 g-xl-9">
              <div class="col-md-4 border-bottom border-gray-300 border-bottom-md-0 pb-7 pb-md-0 mb-8 mb-md-0">
                <div class="border-md-end h-100 pe-md-7 border-gray-300">
                  <div v-if="statistics && statistics.projects && statistics.projects.total">
                    <div class="fs-1 fw-bold">
                      {{ statistics.projects.total }}
                    </div>
                    <div class="text-uppercase text-ls text-muted fs-9 mb-7">Projects</div>
                    <div class="d-flex flex-wrap">
                      <!--begin::Chart-->
                      <div class="position-relative h-150px w-150px me-9 mb-5">
                        <div class="position-relative top-50 start-50 translate-middle">
                          <px-chart-doughnut
                            v-if="!isLoading"
                            ref="ktStarChart2"
                            id="kt_star_chart_2"
                            :data="chartData"
                            :labels="chartLabels"
                            :background-colors="chartBackgroundColors"
                          />
                        </div>
                      </div>
                      <!--end::Chart-->
                      <!--begin::Labels-->
                      <div class="d-flex flex-column justify-content-center flex-row-fluid mb-5">
                        <!--begin::Label-->
                        <div class="d-flex fs-7 text-gray-800 align-items-center mb-3">
                          <div class="bullet bg-gray-300 me-3" />
                          <div>Lead</div>
                          <div class="ms-auto fw-bold">
                            {{ statistics.projects.lead }}
                          </div>
                        </div>
                        <!--end::Label-->
                        <!--begin::Label-->
                        <div class="d-flex fs-7 text-gray-800 align-items-center mb-3">
                          <div class="bullet bg-success me-3" />
                          <div>Signed</div>
                          <div class="ms-auto fw-bold">
                            {{ statistics.projects.signed }}
                          </div>
                        </div>
                        <!--end::Label-->
                        <!--begin::Label-->
                        <div class="d-flex fs-7 text-gray-800 align-items-center mb-3">
                          <div class="bullet bg-primary me-3" />
                          <div>Open</div>
                          <div class="ms-auto fw-bold">
                            {{ statistics.projects.open }}
                          </div>
                        </div>
                        <!--end::Label-->
                        <!--begin::Label-->
                        <div class="d-flex fs-7 text-gray-800 align-items-center">
                          <div class="bullet bg-danger me-3" />
                          <div>Closed</div>
                          <div class="ms-auto fw-bold">
                            {{ statistics.projects.closed }}
                          </div>
                        </div>
                        <!--end::Label-->
                      </div>
                      <!--end::Labels-->
                    </div>
                  </div>
                  <px-placeholder
                    v-if="statistics && statistics.projects && !isLoading && !statistics.projects.total"
                  />
                </div>
              </div>

              <div class="col-md-4 border-bottom border-gray-300 border-bottom-md-0 pb-7 pb-md-0 mb-8 mb-md-0">
                <div v-if="statistics" class="border-md-end h-100 pe-md-7 border-gray-300">
                  <div class="fs-1 fw-bold">
                    {{ $n(statistics.total_projects.total, 'currency', 'en-CA') }}
                  </div>
                  <div class="text-uppercase text-ls text-muted fs-9 mb-7">Total Projects</div>
                  <div class="fs-7 text-gray-800">
                    <div class="d-flex justify-content-between mb-4">
                      <div>Avg. Project Budget</div>
                      <div class="d-flex fw-bold">
                        {{ $n(statistics.total_projects.avg, 'currency', 'en-CA') }}
                      </div>
                    </div>
                    <div class="separator separator-dashed" />
                    <div class="d-flex justify-content-between my-4">
                      <div>Total outstanding</div>
                      <div class="d-flex fw-bold">
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr006.svg-->
                        <span class="svg-icon svg-icon-3 me-1 svg-icon-danger">
                          <inline-svg src="/assets/media/icons/duotune/arrows/arr006.svg" />
                        </span>
                        <!--end::Svg Icon-->
                        {{ $n(statistics.total_projects.outstanding, 'currency', 'en-CA') }}
                      </div>
                    </div>
                    <div class="separator separator-dashed" />
                    <div class="d-flex justify-content-between mt-4">
                      <div>Due immediately</div>
                      <div class="d-flex fw-bold">
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr007.svg-->
                        <span class="svg-icon svg-icon-3 me-1 svg-icon-success">
                          <inline-svg src="/assets/media/icons/duotune/arrows/arr007.svg" />
                        </span>
                        <!--end::Svg Icon-->
                        {{ $n(statistics.total_projects.due, 'currency', 'en-CA') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div v-if="statistics" class="h-100">
                  <div class="fs-1 fw-bold">
                    {{ statistics.contracts.total }}
                  </div>
                  <div class="text-uppercase text-ls text-muted fs-9 mb-7">Contracts</div>

                  <div class="d-flex align-items-center mb-2">
                    <div class="rounded-pill bg-success text-white d-flex me-2 py-2 px-4 fs-8" style="min-width: 238px">
                      <strong class="fw-medium text-nowrap me-3">Landscaping</strong>
                      <span class="opacity-75">
                        {{ $n(statistics.contracts.landscaping.sum, 'currency', 'en-CA') }}
                      </span>
                    </div>
                    <strong class="fs-7 text-gray-800">
                      {{ statistics.contracts.landscaping.qty }}
                    </strong>
                  </div>

                  <div class="d-flex align-items-center mb-2">
                    <div class="rounded-pill bg-info text-white d-flex me-2 py-2 px-4 fs-8" style="min-width: 143px">
                      <strong class="fw-medium text-nowrap me-3">Pool</strong>
                      <span class="opacity-75">
                        {{ $n(statistics.contracts.pool.sum, 'currency', 'en-CA') }}
                      </span>
                    </div>
                    <strong class="fs-7 text-gray-800">
                      {{ statistics.contracts.pool.qty }}
                    </strong>
                  </div>

                  <div class="d-flex align-items-center mb-2">
                    <div class="rounded-pill bg-danger text-white d-flex me-2 py-2 px-4 fs-8" style="min-width: 214px">
                      <strong class="fw-medium text-nowrap me-3">Woodwork</strong>
                      <span class="opacity-75">
                        {{ $n(statistics.contracts.woodwork.sum, 'currency', 'en-CA') }}
                      </span>
                    </div>
                    <strong class="fs-7 text-gray-800">
                      {{ statistics.contracts.woodwork.qty }}
                    </strong>
                  </div>

                  <div class="d-flex align-items-center">
                    <div class="rounded-pill bg-warning text-white d-flex me-2 py-2 px-4 fs-8" style="min-width: 183px">
                      <strong class="fw-medium text-nowrap me-3">Change Orders</strong>
                      <span class="opacity-75">
                        {{ $n(statistics.contracts.change_order.sum, 'currency', 'en-CA') }}
                      </span>
                    </div>
                    <strong class="fs-7 text-gray-800">
                      {{ statistics.contracts.change_order.qty }}
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--begin::Toolbar-->
        <div class="d-flex flex-wrap flex-stack mb-6">
          <!--begin::Heading-->
          <h3 class="mb-0">
            <span class="text-body">Projects</span>
          </h3>
          <!--end::Heading-->

          <!--begin::Actions-->
          <div class="d-flex flex-wrap" />
          <!--end::Actions-->
        </div>
        <!--end::Toolbar-->

        <pxs-projects :manager-id="managerId === 'all' ? null : managerId" @on-archive="onProjectArchive" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import PxNewBreadcrumbs from '@/components/elements/PxNewBreadcrumbs';
import PxChartDoughnut from '@/components/elements/PxChartDoughnut';

import PxsProjects from '@/sections/PxsProjects';

export default {
  components: {
    PxNewBreadcrumbs,
    PxChartDoughnut,
    PxsProjects,
  },
  data() {
    return {
      isLoading: false,
      breadcrumbs: [
        {
          id: '1001',
          title: 'Home',
          to: 'dashboard',
        },
        {
          id: '1002',
          title: 'Projects',
        },
      ],
      chartData: [],
      chartLabels: [],
      chartBackgroundColors: [],
      managerId: 'all',
      innerUsers: [],

      hasMasterRole: false,
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      users: 'users/users',
      statistics: 'projects/statistics',
    }),
  },
  async mounted() {
    this.isLoading = true;

    await this.actionStatistics({ archive: false });
    this.setChartData();

    if (this.user.attributes.groups.data.find((el) => el.attributes.name === 'Master')) this.hasMasterRole = true;

    if (this.hasMasterRole) {
      await this.usersIndex({ groups: ['Manager', 'Master'] });
      this.innerUsers = [
        {
          id: 'all',
          attributes: {
            first_name: 'All',
            last_name: null,
          },
        },
        ...this.users.data,
      ];
    }

    this.isLoading = false;
  },
  methods: {
    ...mapActions({
      actionStatistics: 'projects/statistics',
      usersIndex: 'users/index',
    }),
    async changeManger(val) {
      await this.actionStatistics({
        manager_id: val,
        archive: false,
      });
      await this.resetChartData();
      await this.setChartData();
    },
    async onProjectArchive() {
      await this.actionStatistics({
        manager_id: this.managerId,
        archive: false,
      });
      await this.resetChartData();
      await this.setChartData();
    },
    resetChartData() {
      this.chartData = [];
      this.chartLabels = [];
      this.chartBackgroundColors = [];
    },
    setChartData() {
      if (this.statistics.projects.lead) {
        this.chartData.push(this.statistics.projects.lead);
        this.chartLabels.push('Lead');
        this.chartBackgroundColors.push('#e4e6ef');
      }
      if (this.statistics.projects.signed) {
        this.chartData.push(this.statistics.projects.signed);
        this.chartLabels.push('Signed');
        this.chartBackgroundColors.push('#50cd89');
      }
      if (this.statistics.projects.open) {
        this.chartData.push(this.statistics.projects.open);
        this.chartLabels.push('Open');
        this.chartBackgroundColors.push('#00a3ff');
      }
      if (this.statistics.projects.closed) {
        this.chartData.push(this.statistics.projects.closed);
        this.chartLabels.push('Closed');
        this.chartBackgroundColors.push('#f64e60');
      }
    },
  },
};
</script>
